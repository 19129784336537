<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-0">
      <skeleton-list v-if="$store.state.accounts.LoaderMe || $store.state.purchases.retrieveLoader" />
      <template v-else>
        <PermissionDenied class="mt-n14" v-if="!$helpers.hasSomePermission(['view_purchase'])" />
        <template v-else>
          <v-empty-state :class="{'mt-n2' : routeList.includes($route.name)}" v-if="!purchasesList.length || (!routeList.includes($route.name) && Object.keys($route?.query).length === 0)" type="documents" id="documentos"
          :customClass="routeList.includes($route.name) ? 'mt-n6 pb-2' : 'mt-n8'" :isFree="routeList.includes($route.name)" title="No existen documentos para mostrar" :height="routeList.includes($route.name) ? 199 : 0" :hiddenDescription="hiddenBtnEmpty">
            <template v-slot:actions>
              <template v-if="!routeList.includes($route.name) && hiddenBtnEmpty">
                <v-btn @click="$emit('activeBtnFilter')" color="blue-500" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
                <v-btn class="ml-2" @click="latestRecords()" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
              </template>
            </template>
          </v-empty-state>
          <template v-else>
          <v-data-table
            :class="[{'table-not-rounded' : ['SupplierRetrieveUpdate'].includes($route.name)}, {'fixed-table' : $store.state.base.isExpandNavigationDrawer}]"
            :headers="!routeList.includes($route.name) ? currentHeader : headersDetail"
            :items="purchasesList"
            :items-per-page="pageSize || pagination"
            hide-default-header
            disable-sort
            mobile-breakpoint
            hide-default-footer
            @click:row="(purchasesList) => {goTo(purchasesList.id)}"
            style="cursor: pointer;"
            v-model="selected"
            item-key="id"
            :show-select="!routeList.includes($route.name)"
            checkbox-color="blue-500"
            :fixed-header="!routeList.includes($route.name)"
            :height="routeList.includes($route.name) ? 'auto' : heightTable"
          >
            <!-- header -->
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders :headers="headers" @sort="setSort" @toggleAll="toggleAll" @isSelected="isSelected = $event" :resetCheckbox="resetCheckbox" @toggleDropdown="toggleDropdown = $event" />
              <v-divider style="position: absolute;  margin-top: -6px; margin-left: -5px ;z-index: 2; min-width: 100%" />
            </template>
            <!-- end header -->
            <template v-slot:[`item.supplier`]="{item}">
              <template v-if="!!Object.keys(item.supplier || {}).length && typeof item.supplier === 'object'">
                <span :class="$route.name === 'SupplierRetrieveUpdate' ? 'body-2' : 'body-1 mb-n2'" class="d-inline-block text-truncate" style="max-width: 95%;">{{((item || {}).supplier || {}).name}}</span>
                <span class="caption grey-300--text d-block" v-if="((item || {}).supplier || {}).tax_id">{{$t('generals.RUT')}} {{item.supplier.tax_id}}</span>
                <!-- <span class="body-2 text--disabled d-block font-italic" v-else>Sin especificar ---</span> -->
              </template>
              <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
            </template>
            <template v-slot:[`item.document_type`]="{item}">
              <span class="text-decoration-none grey-700--text">{{item.document_type | nameDocumentsType}}</span>
            </template>
            <template v-slot:[`item.number`]="{item}">
              <span class="body-1">{{item.number}}</span>
            </template>
            <template v-slot:[`item.date`]="{item}">
              <span class="body-1">{{item.date | date}}</span>
            </template>
            <template v-slot:[`item.date_recep_sii`]="{item}">
              <span class="body-1" v-if="item.date_recep_sii">{{item.date_recep_sii | date}}</span>
              <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
            </template>
            <template v-slot:[`item.status`]="{item}">
              <chip-status style="white-space: nowrap !important" :status="item.status ? item.status : 'ERM'" :isTooltip="true" />
            </template>
            <template v-slot:[`item.due_date`]="{item}">
              <span v-if="item.due_date">{{item.due_date | date}}</span>
              <span v-else>---</span>
            </template>
            <template v-slot:[`item.created`]="{item}">
              <span>{{item.created | dateTimeSecond}}</span>
            </template>
            <template v-slot:[`item.total`]="{item}">
              <div class="text-right">
                <span class="body-1">{{item.total | currency(item.document_type)}}</span>
                <span class="grey-300--text caption ml-1">{{item.document_type | firstDocumentsTypeCurrency}}</span>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" :nudge-left="10" transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small><v-icon color="grey-300--text" size="24">mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="px-3" @click="current = item, renderDocument('purchases', null, 'pdf', item.id)" :ripple="false">
                    <v-list-item-title class="body-2 d-flex align-center"><v-icon color="grey-300--text" small left>mdi-text-box</v-icon>Ver PDF</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="px-3" @click="downloadFile('purchases', `${item.document_type}_N${item.number}`, 'purchases', 'xml', item.id)" :ripple="false">
                    <v-list-item-title class="body-2 d-flex align-center"><v-icon color="grey-300--text" small left>mdi-code-braces-box</v-icon>Descargar XML</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <VMainPagination titleShowAll="Ver todos los documentos" :count="purchasesList?.length" redirect="purchasesList" />
          </template>
        </template>
      </template>
      <!-- dialog pdf -->
      <v-dialog v-model="modalRender" width="700" persistent no-click-animation overlay-color="grey-500">
        <render-pdf :documentType="current.document_type | nameDocumentsType" :number="current.number" :loaderRender="loaderRender" :renderUrl="renderUrl" @close="modalRender=false" :key="current.number" />
      </v-dialog>
      <!-- end dialog pdf -->
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VEmptyState from '@/components/commons/VEmptyState'
import RenderPdf from '@/components/files/RenderPdf'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import RenderViewMixin from '@/mixins/RenderViewMixin'
import ChipStatus from '@/components/commons/ChipStatus'
import VMainPagination from '@/components/commons/VMainPagination'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  components: {
    ChipStatus,
    RenderPdf,
    VEmptyState,
    SkeletonList,
    VTableHeaders,
    VMainPagination,
    PermissionDenied
  },
  mixins: [
    ListViewMixin,
    GenericViewMixin,
    RenderViewMixin
  ],
  props: {
    headerList: {
      type: Array,
      default: () => ([])
    },
    pageSize: {
      default: null
    }
  },
  data: () => ({
    list: [],
    toggleDropdown: null,
    current: {},
    resetCheckbox: 0,
    isSelected: null,
    selected: [],
    routeList: ['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve', 'SupplierRetrieveUpdate'],
    headersDetail: [
      { text: 'Proveedor', value: 'supplier', sortable: true },
      { text: 'Tipo de documento', value: 'document_type', sortable: true },
      { text: 'Folio #', value: 'number', align: 'end', sortable: true },
      { text: 'Fecha', value: 'date', align: 'end', sortable: true },
      // { text: 'Estado', value: 'status', width: 130 },
      { text: 'Total', value: 'total', align: 'end', sortable: true, width: 135 },
      { text: '', value: 'actions', align: 'end', width: 16 }
    ]
  }),
  computed: {
    heightTable () {
      if (this.$vuetify.breakpoint.height >= 800 && (this.purchasesList.length < 17 || ['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve', 'SupplierRetrieveUpdate'].includes(this.$route.name))) return 'auto'
      return 'calc(100vh - 275px)'
    },
    ...mapState({
      count: state => state.purchases.purchasesCount,
      purchasesList: state => state.purchases.purchasesList
    }),
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (this.purchasesList.length) return false
      return false
    },
    currentHeader () {
      let list = this.list
      list.forEach((item) => {
        if (['SupplierRetrieveUpdate'].includes(this.$route.name)) {
          // if (item.value === 'document_type') item.text = 'T.doc'
          if (item.value === 'number') item.text = this.$t('generals.Folio#')
        } else if (this.$route.name === 'PurchaseList') {
          if (this.$vuetify.breakpoint.width < 1210 && item.value === 'document_type') {
            // item.text = 'T. documento'
          }
        }
        if (this.$store.state?.auth?.account?.country === 'PE' && item.value === 'number') {
          item.align = 'start'
          item.text = this.$t('generals.Folio #')
        }
      })
      return !this.$helpers.hasPermission(['add_swapresponse']) ? list.filter((h) => h.value !== 'status') : list
    }
  },
  watch: {
    selected (val) {
      if (val.length !== this.purchasesList.length) this.resetCheckbox += 1

      this.$emit('update:selectedDocuments', this.selected)
    },
    toggleDropdown (val) {
      if (['filtered', 'perPage', 'all'].includes(val)) {
        this.selected = this.purchasesList
        this.isSelected = true
      } else if (val === '') this.selected = []
      this.$emit('update:toggleDropdown', val)
    },
    headerList () {
      this.list = [...this.headerList].filter((item) => item.show)
    }
  },
  methods: {
    toggleAll () {
      this.selected = !this.isSelected ? [] : this.purchasesList
    },
    goTo (id = null) {
      this.$store.commit('base/SET_QUERY', this.$route.query)
      this.$router.push({ name: 'PurchaseRetrieve', params: { id: id }})
    }
  }
}
</script>